var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.Headers,"items":_vm.Items,"hide-default-footer":!_vm.ToLinked,"item-class":_vm.getClassColor,"options":_vm.pagination,"server-items-length":_vm.TotalItems,"footer-props":{
            'items-per-page-options': [10, 20, 50, 100],
            'items-per-page-text': 'Itens por página',
            'show-first-last-page': true,
            'page-text': `Página ${_vm.pagination.page} de ${
                parseInt(_vm.TotalItems / _vm.pagination.itemsPerPage) + 1
            }`,
        }},on:{"update:options":function($event){_vm.pagination=$event}},scopedSlots:_vm._u([{key:`item.data-table-expand`,fn:function({ item, isExpanded, expand }){return [(item.observation && !isExpanded)?_c('v-icon',{on:{"click":function($event){return expand(true)}}},[_vm._v("mdi-chevron-down")]):_vm._e(),(item.observation && isExpanded)?_c('v-icon',{on:{"click":function($event){return expand(false)}}},[_vm._v("mdi-chevron-up")]):_vm._e()]}},{key:"expanded-item",fn:function({ headers, item }){return [_c('td',{attrs:{"colspan":headers.length}},[_vm._v(" Observações: "+_vm._s(item.observation)+" ")])]}},{key:`item.current_week`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.current_week)))])]}},{key:`item.pending_payment`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.pending_payment)))])]}},{key:`item.changed_value`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatMoney(item.changed_value)))])]}},{key:`item.date_created`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatDate(item.date_created)))])]}},{key:`item.moviment_type`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getTypeMoviment(item.moviment_type)))])]}},{key:`item.origin`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.getOrigin(item.origin)))])]}},{key:`item.ligations`,fn:function({ item }){return [_c('span',[_vm._v(_vm._s(_vm.formatTextVicnulations( item.linked_to, item.total_vinculations, )))])]}},(_vm.ToLinked)?{key:`item.actions`,fn:function({ item }){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"small":"","color":"black"},on:{"click":function($event){return _vm.link(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-link-variant")])]}}],null,true)},[_c('span',[_vm._v("Vincular")])])]}}:null],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }